export const minYear = 1013;

export const maxYear = 9985;

const generatePanelYears = (year) => {
	let y = 0;

	if (year < minYear) {
		y = minYear;
	} else if (year > maxYear) {
		y = maxYear;
	} else {
		y = year;
	}

	const years = [];

	for (let i = 0; i < 4; i++) {
		years.unshift(y - i);
	}

	for (let i = 0; i < 3; i++) {
		years.push(y + 1 + i);
	}

	return years;
};

export const generateYears = (year) => {
	let y = 0;

	if (year < minYear) {
		y = minYear;
	} else if (year > maxYear) {
		y = maxYear;
	} else {
		y = year;
	}

	const years = [];

	for (let i = 0; i < 14; i++) {
		years.unshift(y - i);
	}

	for (let i = 0; i < 14; i++) {
		years.push(y + 1 + i);
	}

	return years;
};

export default generatePanelYears;
