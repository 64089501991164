import { memo, useMemo, useCallback } from "react";

import classNames from "common/class-names";
import generateDates from "./common/generate-dates";
import validateSameDate from "./common/validate-same-date";

const UnicornDates = (props) => {
	const today = useMemo(() => new Date(), []);

	const selected = useMemo(() => {
		const month = props.month > -1 ? props.month : today.getMonth();

		const year = props.year > -1 ? props.year : today.getFullYear();

		return new Date(year, month, 1);
	}, [today, props.month, props.year]);

	const dates = useMemo(() => generateDates(selected ?? today, props.datePattern), [selected, today, props.datePattern]);

	// prettier-ignore
	const onHandleGetDisabledDate = useCallback((c) => {
		const maxDate = props.maxDate ?? new Date(9999, 11, 31);
		const minDate = props.minDate ?? new Date(1000, 0, 31);
		

		return maxDate < c._d || minDate > c._d;

	}, [props.maxDate, props.minDate]);

	return (
		<div className="unicorn-dates">
			<div className="dates">
				{dates.map((c, i) => {
					const selected = props.date ? validateSameDate(props.date, c._d) : false;

					const isDisabled = onHandleGetDisabledDate(c);

					const backdropClassName = classNames({ dates__backdrop: true, "dates__backdrop-selected": selected });

					const textClassName = classNames({ dates__text: true, "dates__same-month": c.sameMonth, dates__today: c.today });

					return (
						<button key={i} type="button" className="dates__date" disabled={isDisabled} onClick={() => props.onChange(c.date.fullDate)}>
							<div className="dates__inner">
								<div className={backdropClassName}>
									<p className={textClassName}>{c.date.date}</p>
								</div>
							</div>
						</button>
					);
				})}
			</div>
		</div>
	);
};

export default memo(UnicornDates);
