import pathnames from "routes/pathnames";

import AppDashboardIcon from "components/icons/app-dashboard-icon";

const sideNavList = [
	{
		label: "Dashboard",
		path: pathnames.dashboard,
		icon: AppDashboardIcon,
		childrens: [],
		relatives: [pathnames.invoice.createEdit, pathnames.invoice.bulkUpload],
	},
];

export default sideNavList;
