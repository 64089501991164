const getStartDate = (currentDate) => {
	const year = currentDate.getFullYear();

	const month = currentDate.getMonth();

	const date = currentDate.getMonth() + 1;

	const nextDate = new Date(year, month, date).setDate(1);

	return new Date(nextDate);
};

export default getStartDate;
