const generateDays = (format) => {
	const days = {
		dddd: [
			{ day: "Monday", value: 1 },
			{ day: "Tuesday", value: 2 },
			{ day: "Wednesday", value: 3 },
			{ day: "Thursday", value: 4 },
			{ day: "Friday", value: 5 },
			{ day: "Saturday", value: 6 },
			{ day: "Sunday", value: 0 },
		],
		ddd: [
			{ day: "Mo", value: 1 },
			{ day: "Tu", value: 2 },
			{ day: "We", value: 3 },
			{ day: "Th", value: 4 },
			{ day: "Fr", value: 5 },
			{ day: "Sa", value: 6 },
			{ day: "Su", value: 0 },
		],
		dd: [
			{ day: "M", value: 1 },
			{ day: "T", value: 2 },
			{ day: "W", value: 3 },
			{ day: "T", value: 4 },
			{ day: "F", value: 5 },
			{ day: "S", value: 6 },
			{ day: "S", value: 0 },
		],
	};

	return days[format];
};

export default generateDays;
