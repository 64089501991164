const ERRORS = {
	REQUIRED: "This field is required.",
	LENGTH: "Use at least 8 characters",
	NUMERIC: "Should contain at least 1 numeric",
	UPPERCASE: "Should contain at least 1 uppercase",
	LOWERCASE: "Should contain at least 1 lowercase",
	SYMBOL: "Should contain at least 1 special character",
	FILE_SIZE: "File Size is too large (maximum 5MB)",
	EMAIL: "Please provide a valid email",
};

export default ERRORS;
