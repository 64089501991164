import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";

import AppIcon from "components/app-icon";
import classNames from "common/class-names";

const AppButton = (props) => {
	const className = useMemo(() => {
		return classNames({
			"app-button": true,
			"app-button--outline": props.outline || false,
			"app-button--large": props.large || false,
			...(props.className && {
				[props.className]: true,
			}),
		});
	}, [props.className, props.large, props.outline]);

	return (
		<button className={className} type={props.type} disabled={props.disabled} onClick={props.onClick}>
			<p className="app-button__label">{props.label}</p>
			
			{props.customIcon && props.customIcon}

			{props.icon && <AppIcon src={props.icon} />}
		</button>
	);
};

AppButton.propTypes = {
	onClick: PropTypes.func,
	outline: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	label: PropTypes.string.isRequired,
	type: PropTypes.oneOf(["button", "submit", "reset"]).isRequired,
};

export default memo(AppButton);
