import React, { useState } from "react";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { PersistGate } from "redux-persist/integration/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import theme from "themes/theme";
import enrichedRouters from "routes/routes";
import store, { persistor } from "store/store";
import AppModalAlert from "components/app-modal-alert";
import InterceptorProvider from "contexts/with-interceptor-provider";

const App = () => {
	const [routes, setRoutes] = useState(null);

	const onHandleInitialize = () => {
		const router = createBrowserRouter(enrichedRouters);
		setRoutes(router);
	};

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Provider store={store}>
				<PersistGate persistor={persistor} onBeforeLift={onHandleInitialize}>
					<AppModalAlert />

					<InterceptorProvider routes={routes}>{routes && <RouterProvider router={routes} />}</InterceptorProvider>
				</PersistGate>
			</Provider>
		</ThemeProvider>
	);
};

export default App;
