import React, { memo, useMemo, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import classNames from "common/class-names";
import useWindowSize from "hooks/use-window-size";
import { dismissAlertMessage } from "store/slices/alert";
import checkedIcon from "assets/images/components/app-alert/checked-icon.svg";

const AppAlert = () => {
	const timer = useRef();
	const resetTimer = useRef();
	const dispatch = useDispatch();
	const alert = useSelector((state) => state.alert);
	const [active, setActive] = useState(false);
	const errorCode = useMemo(() => (alert.code ? `(Error Code : ${alert.code})` : ""), [alert.code]);
	const [width] = useWindowSize();

	const className = useMemo(() => {
		return classNames({
			"app-alert": true,
			"app-alert--active": active,
			"app-alert--error": alert.error,
		});
	}, [alert, active]);

	useEffect(() => {
		if (alert.message) {
			clearTimeout(timer.current);
			clearTimeout(resetTimer.current);
			setActive(true);

			timer.current = setTimeout(() => {
				const alert = document.getElementById("app-alert");

				alert.style.left = 0;

				setTimeout(() => {
					setActive(false);
				}, 5);

				resetTimer.current = setTimeout(() => {
					dispatch(dismissAlertMessage());
				}, 1000);
			}, 5000);
		}
	}, [alert, dispatch]);

	useEffect(() => {
		if (width && alert.message) {
			const alert = document.getElementById("app-alert");
			const page = document.getElementById("main-page");

			const left = width - page.offsetWidth;

			alert.style.left = left + "px";
		}
	}, [width, alert]);

	if (!alert?.message || typeof alert?.message !== "string") return null;

	return (
		<div className={className} id="app-alert">
			<div className="alert">
				{!alert.error && <img className="alert__icon" src={checkedIcon} alt="checked" />}
				<p className="alert__text">
					{alert.message} {errorCode}
				</p>
			</div>
		</div>
	);
};

export default memo(AppAlert);
