import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Modal, FormControl, TextField, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";

import getEndDate from "./common/get-end-date";
import UnicornDays from "./unicorn-days";
import UnicornDates from "./unicorn-dates";
import UnicornYears from "./unicorn-years";
import UnicornMonths from "./unicorn-months";
import AppIcon from "components/app-icon";
import lockIcon from "assets/images/components/app-input/lock-icon.svg";
import calendarIcon from "assets/images/components/app-calendar-input/calendar-icon.png";

const AppUnicornCalendarInput = (props) => {
	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);

	const errorMessage = useMemo(() => (isErrorField ? props.error : ""), [props.error, isErrorField]);

	const [visible, setVisible] = useState(false);

	const [currentDate, setCurrentDate] = useState(props.value);

	const [selectedMonth, setSelectedMonth] = useState(props.value?.getMonth?.() ?? -1);

	const [selectedYear, setSelectedYear] = useState(props.value?.getFullYear?.() ?? -1);

	const displayDate = useMemo(() => {
		if (!currentDate) return "";

		const d = new Date(currentDate);

		return [d.getDate().toString().padStart(2, "0"), (parseInt(d.getMonth()) + 1).toString().padStart(2, "0"), d.getFullYear()].join("/");
	}, [currentDate]);

	const disabledConfirm = useMemo(() => {
		let disabled = !currentDate;

		let sameAsMaxDate = false;

		let sameAsMinDate = false;

		if (props.maxDate && currentDate) sameAsMaxDate = props.maxDate < currentDate;

		if (props.minDate && currentDate) sameAsMinDate = props.minDate > currentDate;

		return disabled || sameAsMaxDate || sameAsMinDate;
	}, [currentDate, props.maxDate, props.minDate]);

	/* prettier-ignore */
	const onHandleChangeMonth = useCallback((m) => {
		
		setCurrentDate((prevDate) => {
			if(prevDate) {
				const currentDate = prevDate.getDate();
				
				const currentYear = prevDate.getFullYear();
				
				const currentMonthLastDate = getEndDate(new Date(currentYear, m, 1)).getDate();
				
				let nextDate = currentDate;

				if(currentDate > currentMonthLastDate) nextDate = currentMonthLastDate

				return new Date(currentYear, m, nextDate)
			}
			else {
				return prevDate;
			} 
		});

		setSelectedMonth(m);
	}, []);

	/* prettier-ignore */
	const onHandleChangeDate = useCallback((d) => {
		const datePattern = props.datePattern ?? "-";		

		const arrayDate = d.split(datePattern).map(o => parseInt(o));

		const date = arrayDate[0];

		const month = arrayDate[1] - 1;

		const year = arrayDate[2];

		setSelectedYear(year);

		setSelectedMonth(month);

		setCurrentDate(new Date(year, month, date));
	}, [props.datePattern]);

	/* prettier-ignore */
	const onHandleChangeYear = useCallback((y) => {
		setSelectedYear(y);

		setCurrentDate((prevDate) => {
			if(prevDate) {
				const currentDate = prevDate.getDate();
				
				const currentMonth = prevDate.getMonth();
				
				const currentMonthLastDate = getEndDate(new Date(y, currentMonth, 1)).getDate();
				
				let nextDate = currentDate;

				if(currentDate > currentMonthLastDate) nextDate = currentMonthLastDate

				return new Date(y, currentMonth, nextDate)
			}
			else {
				return prevDate;
			} 
		});
	}, []);

	const onHandleSelectDate = useCallback(() => {
		props.onChange(props.name, currentDate);

		setVisible(false);
	}, [props, currentDate]);

	//prettier-ignore
	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useEffect(() => {
		if (props.value && !currentDate) {
			setCurrentDate(props.value);

			setSelectedYear(props.value.getFullYear());

			setSelectedMonth(props.value.getMonth());
		}
	}, [props.value, currentDate]);

	const InputProps = useMemo(() => {
		const inputProps = {};

		const endAdornment = {};

		if (props.disabled) {
			endAdornment.endAdornment = (
				<InputAdornment position="end">
					<AppIcon src={lockIcon} />
				</InputAdornment>
			);
		} else {
			endAdornment.endAdornment = (
				<InputAdornment position="end">
					<AppIcon src={calendarIcon} />
				</InputAdornment>
			);
		}

		Object.assign(inputProps, endAdornment);

		return inputProps;
	}, [props.disabled]);

	return (
		<Fragment>
			<Modal classes={{ root: "unicorn-calendar-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="unicorn-calendar-modal" aria-describedby="unicorn-calendar-modal">
				<div className="unicorn-calendar">
					<div className="calendar">
						<div className="calendar__container">
							<UnicornYears minDate={props.minDate} maxDate={props.maxDate} year={selectedYear} onChange={onHandleChangeYear} />

							<UnicornMonths minDate={props.minDate} maxDate={props.maxDate} year={selectedYear} month={selectedMonth} onChange={onHandleChangeMonth} />

							<UnicornDays />

							<UnicornDates date={currentDate} month={selectedMonth} year={selectedYear} minDate={props.minDate} maxDate={props.maxDate} datePattern={props.datePattern} onChange={onHandleChangeDate} />
						</div>

						<div className="calendar__footer">
							<button type="button" className="calendar__button" onClick={onHandleDismiss}>
								<p className="calendar__text">Cancel</p>
							</button>

							<button type="button" className="calendar__button calendar__solid" onClick={onHandleSelectDate} disabled={disabledConfirm}>
								<p className="calendar__text">Confirm</p>
							</button>
						</div>
					</div>
				</div>
			</Modal>

			<div className="app-calendar-input-2">
				<div className="calendar-input-2">
					<FormControl error={isErrorField}>
						<label className="calendar-input-2__label" htmlFor={props.name}>
							{props.label}
							{props.required && <span className="calendar-input-2__required">*</span>}
						</label>

						<button type="button" className="calendar-input-2__wrapper" onClick={onHandleShow}>
							<TextField autoComplete="off" value={displayDate} type="text" name={props.name} error={isErrorField} helperText={errorMessage} disabled placeholder={props.placeholder || "DD/MM/YYYY"} InputProps={InputProps} />
						</button>
					</FormControl>
				</div>
			</div>
		</Fragment>
	);
};

export default AppUnicornCalendarInput;

AppUnicornCalendarInput.propTypes = {
	value: PropTypes.instanceOf(Date),
	error: PropTypes.string,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	placeholder: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};
