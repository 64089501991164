const downloadFile = async (data, fileName) => {
	const a = document.createElement("a");

	document.body.appendChild(a);

	const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));

	a.href = url;
	a.download = fileName;
	a.click();

	setTimeout(() => {
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);
	}, 0);
};

export default downloadFile;
