const COMMON = {
	REDUX_ACTION: {
		LOGOUT: "LOGOUT",
	},
	REDUX_PRESIST_KEY: "root_@a05a8127-4deb-437b-b9dc-a9025b802f51",
	AUTH_TOKEN: "e-invoce-demo_@a05a8127-4deb-437b-b9dc-a9025b802f51_auth_tk",
	REFRESH_TOKEN: "e-invoce-demo_@a05a8127-4deb-437b-b9dc-a9025b802f51_refresh_tk",
	SIDE_NAV_SHRINKED: "e-invoce-demo_@a05a8127-4deb-437b-b9dc-a9025b802f51",
	ENDPOINT_PATH: {
		INVOICE: {
			CREATE: "/invoice",
			INVOICE: "/invoice/",
			INVOICES: "/invoice",
			DOWNLOAD: "/invoice/download/",
			BATCH_INVOICES: "/invoice/batch",
		},
	},
	REGEX: {
		SYMBOL: /(\W|_)/,
		NUMERIC: /(?=.*\d)/,
		LOWERCASE: /(?=.*[a-z])/,
		UPPERCASE: /(?=.*[A-Z])/,
		EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	},
	MASKING: {
		NRIC: "******-**-****",
	},
	IDENTIFICATION_TYPE: {
		NRIC: "NRIC",
		PASSPORT: "PASSPORT",
	},
	MAX_FILE_SIZES: {
		MB_5: 5000000,
		MB_15: 15000000,
	},
	PAGE: {
		CREATE: "create",
	},
	STATUS_CODE: {
		TOKEN_EXPIRED: "MC_000011",
		INVALID_TOKEN: "MC_000016",
		REFRESH_TOKEN_EXPIRED: "MC_000017",
		USER_BLOCKED: "MC_000018",
		MAXIMUM_PASSWORD_ATTEMPTS: "MC_UAA_00010",
	},
};

export default COMMON;
