import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import AppArrowIcon from "components/icons/app-arrow-icon";
import logo from "assets/images/atf-icon.png";

const AppHeader = ({ title = "Dashboard", ...props }) => {
	const navigate = useNavigate();

	return (
		<header className="app-header">
			<div className="header">
				<button type="button" id="header-menu" className="header__menu" onClick={props.onHandleToggleSideNav} />
				{title && <h1 className="header__title">{title}</h1>}
				{!title && (
					<button className="header__back" onClick={() => navigate(-1)}>
						<div className="header__back-icon">
							<AppArrowIcon color="#0d4892" />
						</div>
						Back to lists
					</button>
				)}

				<div className="header__profile">
					<div className="header__content">
						<p className="header__name">ALTECFLEX</p>
						<p className="header__text">Super Admin</p>
					</div>

					<div className="header__image">
						<img src={logo} alt="logo" />
					</div>
				</div>
			</div>
		</header>
	);
};

export default memo(AppHeader);
