import COMMON from "common";
import services from "services";
import encodeQueryParams from "common/encode-query-params";

const api = {
	get: {
		invoice: {
			invoice: (params) => services.get(COMMON.ENDPOINT_PATH.INVOICE.INVOICE + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.INVOICE.INVOICE }),
			invoices: (params) => services.get(COMMON.ENDPOINT_PATH.INVOICE.INVOICES + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.INVOICE.INVOICES }),
			download: (params, headers) => services.get(COMMON.ENDPOINT_PATH.INVOICE.DOWNLOAD + encodeQueryParams(params), { ...headers, responseType: "arraybuffer" }),
		},
	},
	post: {
		invoice: {
			create: (payload) => services.post(COMMON.ENDPOINT_PATH.INVOICE.CREATE, payload),
			batch: (payload) => services.post(COMMON.ENDPOINT_PATH.INVOICE.BATCH_INVOICES, payload),
		},
	},
	patch: {},
	put: {},
};

export default api;
