import { memo, useCallback, useMemo } from "react";

import classNames from "common/class-names";
import CalendarFormat from "./common/calendar-format";
import generateMonths from "./common/generate-months";

const UnicornMonths = (props) => {
	const today = useMemo(() => new Date(), []);

	const months = useMemo(() => generateMonths(CalendarFormat.Month_mmm), []);

	// prettier-ignore
	const onHandleGetDisabledMonth = useCallback((d) => {
		const year = props.year < 0 ? today.getFullYear() : props.year;

		const minYear = props.minDate ? props.minDate.getFullYear() : -1;

		const maxYear = props.maxDate ? props.maxDate.getFullYear() : -1;

		const minMonth = props.minDate ? props.minDate.getMonth() : -1;
		
		const maxMonth = props.maxDate ? props.maxDate.getMonth() : -1;

		const isMinMonth = year <= minYear && minMonth > d.value;

		const isMaxMonth = ((year >= maxYear) && maxYear > 0) && maxMonth < d.value;

		return isMinMonth || isMaxMonth;
	}, [props.year, props.minDate, props.maxDate, today]);

	return (
		<div className="unicorn-months">
			<div className="months">
				{months.map((d, i) => {
					const selected = props.month === d.value;

					const months = d.month.split("").join("\n");

					const currentMonth = new Date().getMonth() === d.value;

					const isDisabled = onHandleGetDisabledMonth(d);

					const monthInnerClassName = classNames({ months__inner: true, "months__inner-selected": selected });

					const textClassName = classNames({ months__text: true, months__today: currentMonth });

					return (
						<button type="button" key={i} className="months__month" disabled={isDisabled} onClick={() => props.onChange(d.value)}>
							<div className={monthInnerClassName}>
								<p className={textClassName}>{months}</p>
							</div>
						</button>
					);
				})}
			</div>
		</div>
	);
};

export default memo(UnicornMonths);
