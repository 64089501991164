import React, { useCallback, useEffect, useMemo, useState, useRef, Fragment } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import { useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";

import COMMON from "common";
import api from "services/api";
import STATUS from "common/status";
import pathnames from "routes/pathnames";
import { formatDateTime } from "common/calendar";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppTable, { MoreTableView } from "components/app-table";
import AppInvoiceModal from "components/pages/page-dashboard/app-invoice-modal";
import { ReactComponent as AddIcon } from "assets/images/pages/invoice/plus-icon.svg";
import { ReactComponent as UploadIcon } from "assets/images/pages/invoice/file-upload-icon.svg";

const PageDashboard = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const selectedInvoiceId = useRef();
	const invoiceModalRef = useRef();
	const onHandleCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const columnHelper = useMemo(() => createColumnHelper(), []);
	const [data, setData] = useState([]);
	const [fetching, setFetching] = useState(false);
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [scheduleInvoice, setScheduleInvoice] = useState(false);

	const onHandleCloseMenu = useCallback(() => {
		selectedInvoiceId.current = null;
		setMenuAnchor(null);
	}, []);

	const onHandleSendAllPendingInvoices = useCallback(() => {
		setData((prev) => prev.map((o) => ({ ...o, status: STATUS.COMPLETED })));
		dispatch(promptAlertMessage({ message: "All pending invoices has been sent." }));
	}, []);

	//prettier-ignore
	const onHandleSelectMenu = useCallback((event) => {
		const value = event.target.getAttribute("data-value");

		switch (value) {
			case "SEND_INVOICE":
				setData(prev => prev.map((o) => o.id === selectedInvoiceId.current ?  ({...o, status: STATUS.COMPLETED }) : o));
				dispatch(promptAlertMessage({ message: "Invoice has been sent." }));
				break;
			case "VIEW":
				invoiceModalRef.current.onHandleShow(selectedInvoiceId.current);
				break;
			case "EDIT":
				navigate(pathnames.invoice.createEdit + selectedInvoiceId.current);
				break;
		}

		onHandleCloseMenu();
	}, [dispatch, navigate, onHandleCloseMenu]);

	const onHandletGetList = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.invoice.invoices();
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setFetching(false);
		}

		if (response) {
			setData(response.data);
		}
	}, []);

	//prettier-ignore
	const onHandleEdit = useCallback((event, obj) => {
		const id = obj.cell.row.original.id;

		selectedInvoiceId.current = id;

		setMenuAnchor(event.currentTarget);
	}, []);

	//prettier-ignore
	const onHandleCreate = useCallback(() => {
		navigate(pathnames.invoice.createEdit + COMMON.PAGE.CREATE);
	}, [navigate]);

	const onHandleScheduleInvoices = useCallback(() => {
		setScheduleInvoice((prev) => !prev);
	}, []);

	//prettier-ignore
	const onHandleUploadEinvoice = useCallback(() => {
		navigate(pathnames.invoice.bulkUpload);
	}, [navigate]);

	const ScheduledSwitch = useCallback(() => {
		return (
			<Fragment>
				<Tooltip placement="bottom-end" title="Upon activation, the system will commence the scheduling procedure for dispatching all pending e-invoices to LHDN at precisely 12:00 AM.">
					<p className="dashboard__info">!</p>
				</Tooltip>
				<div className="dashboard__switch">
					<Switch checked={scheduleInvoice} onChange={onHandleScheduleInvoices} />
				</div>
			</Fragment>
		);
	}, [scheduleInvoice, onHandleScheduleInvoices]);

	//prettier-ignore
	const columns = useMemo(() => [
		columnHelper.accessor("invoiceNumber", {
			header: "Invoice No.",
			cell: (c) => c.getValue() ?? "-",
		}),
		columnHelper.accessor("clientName", {
			header: "Name",
		}),
		columnHelper.accessor("clientEmail", {
			header: "Email Address",
		}),
		columnHelper.accessor("clientPhoneNumber", {
			header: "Mobile No.",
		}),
		columnHelper.accessor("total", {
			header: "Total",
			cell: (c) => formatCurrency(c.getValue()),
		}),
		columnHelper.accessor("createdAt", {
			header: "Created At",
			cell: (c) => formatDateTime(c.getValue()),
		}),
		columnHelper.accessor("status", {
			header: "Status",
			cell: (c) => <AppStatus status={c.getValue()} />,
		}),
		columnHelper.accessor("edit", {
			header: () => <ScheduledSwitch />,
			cell: (c) => <MoreTableView onClick={(e) => onHandleEdit(e, c)} />,
		}),
	], [columnHelper, onHandleEdit, ScheduledSwitch]);

	useEffect(() => {
		setFetching(true);

		onHandletGetList();
	}, [onHandletGetList]);

	useEffect(() => {
		return () => {
			onHandleCancelRequest(COMMON.ENDPOINT_PATH.INVOICE.INVOICES);
		};
	}, [onHandleCancelRequest]);

	const Icon = useCallback(({ className, icon }) => {
		return <span className={"app-button__icon " + className}>{icon}</span>;
	}, []);

	return (
		<div className="page-dashboard">
			<div className="dashboard">
				<div className="dashboard__header">
					<div className="dashboard__header-wrapper">
						<AppButton type="button" outline label="UPLOAD E-INVOICE" customIcon={<Icon className="app-button__icon--upload" icon={<UploadIcon />} />} onClick={onHandleUploadEinvoice} />

						<AppButton type="button" label="CREATE E-INVOICE" customIcon={<Icon className="app-button__icon--add" icon={<AddIcon />} />} onClick={onHandleCreate} />
					</div>
				</div>

				<div className="dashboard__send-all-invoices">
					<AppButton type="button" outline label="SEND ALL PENDING INVOICES" onClick={onHandleSendAllPendingInvoices} />
				</div>

				<AppTable columns={columns} data={data} fetching={fetching} />
			</div>

			<AppInvoiceModal ref={invoiceModalRef} />

			{/* prettier-ignore */}
			<Menu classes={{ root: "app-dashboard-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }} onClose={onHandleCloseMenu}>
				<MenuItem data-value="SEND_INVOICE" onClick={onHandleSelectMenu}>Send invoice</MenuItem>
				<MenuItem data-value="VIEW" onClick={onHandleSelectMenu}>View</MenuItem>
				<MenuItem data-value="EDIT" onClick={onHandleSelectMenu}>Edit</MenuItem>
			</Menu>
		</div>
	);
};

export default PageDashboard;
