import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";

import STATUS from "common/status";
import classNames from "common/class-names";
import capitalizeCharacter from "common/capitalize-character";

const AppStatus = (props) => {
	const text = useMemo(() => props.status?.toUpperCase(), [props.status]);

	const red = useMemo(() => {
		switch (text) {
			case STATUS.FAILED:
				return true;
			default:
				return false;
		}
	}, [text]);

	const green = useMemo(() => {
		switch (text) {
			case STATUS.NEW:
			case STATUS.COMPLETED:
				return true;
			default:
				return false;
		}
	}, [text]);

	const yellow = useMemo(() => {
		switch (text) {
			case STATUS.PENDING:
				return true;
			default:
				return false;
		}
	}, [text]);

	const className = useMemo(() => {
		return classNames({ status: true, "status--green": green, "status--red": red, "status--yellow": yellow });
	}, [red, green, yellow]);

	const status = useMemo(() => {
		return capitalizeCharacter(text.split("_").join(" "));
	}, [text]);

	return (
		<div className="app-status">
			<div className={className}>
				<p className="status__text">{status}</p>
			</div>
		</div>
	);
};

export default memo(AppStatus);

AppStatus.propTypes = {
	status: PropTypes.string.isRequired,
};
