import COMMON from "common";
import pathnames from "routes/pathnames";
import AppLayout from "components/app-layout";

import Page404 from "pages/page-404";
import PageDashboard from "pages/page-dashboard";
import PageInvoiceCreateEdit from "pages/page-invoice-create-edit";
import PageInvoiceBulkUpload from "pages/page-invoice-bulk-upload";

const token = localStorage.getItem(COMMON.AUTH_TOKEN) || sessionStorage.getItem(COMMON.AUTH_TOKEN);

const routes = [
	{
		path: pathnames.dashboard,
		element: <PageDashboard />,
		authenticated: true,
	},
	{
		path: pathnames.invoice.bulkUpload,
		element: <PageInvoiceBulkUpload />,
		authenticated: true,
	},
	{
		path: pathnames.invoice.createEdit + ":id",
		element: <PageInvoiceCreateEdit />,
		authenticated: true,
	},
	{
		path: "*",
		element: <Page404 />,
		authenticated: true,
	},
];

const enrichedRouters = routes.map(({ authenticated, ...res }) => {
	if (!authenticated) return { ...res };

	return { ...res, element: <AppLayout>{res.element}</AppLayout>, errorElement: <Page404 /> };
});

export default enrichedRouters;
