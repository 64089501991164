const generateMonths = (format) => {
	const months = {
		MMMM: [
			{ month: "January", value: 0 },
			{ month: "February", value: 1 },
			{ month: "March", value: 2 },
			{ month: "April", value: 3 },
			{ month: "May", value: 4 },
			{ month: "June", value: 5 },
			{ month: "July", value: 6 },
			{ month: "August", value: 7 },
			{ month: "September", value: 8 },
			{ month: "October", value: 9 },
			{ month: "November", value: 10 },
			{ month: "December", value: 11 },
		],
		MMM: [
			{ month: "Jan", value: 0 },
			{ month: "Feb", value: 1 },
			{ month: "Mar", value: 2 },
			{ month: "Apr", value: 3 },
			{ month: "May", value: 4 },
			{ month: "Jun", value: 5 },
			{ month: "Jul", value: 6 },
			{ month: "Aug", value: 7 },
			{ month: "Sep", value: 8 },
			{ month: "Oct", value: 9 },
			{ month: "Nov", value: 10 },
			{ month: "Dec", value: 11 },
		],
		MM: [
			{ month: "0", value: 0 },
			{ month: "1", value: 1 },
			{ month: "2", value: 2 },
			{ month: "3", value: 3 },
			{ month: "4", value: 4 },
			{ month: "5", value: 5 },
			{ month: "6", value: 6 },
			{ month: "7", value: 7 },
			{ month: "8", value: 8 },
			{ month: "9", value: 9 },
			{ month: "10", value: 10 },
			{ month: "11", value: 11 },
		],
	};

	return months[format];
};

export default generateMonths;
