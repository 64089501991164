const CalendarFormat = {
	Date: "DD",
	Month: "MMMM",
	Month_mmm: "MMM",
	Month_mm: "MM",
	Year: "YYYY",
	Weekday: "dddd",
	Weekday_ddd: "ddd",
	Weekday_dd: "dd",
};

export const ShortDays = new Map([
	[0, "Sun"],
	[1, "Mon"],
	[2, "Tues"],
	[3, "Wed"],
	[4, "Thur"],
	[5, "Fri"],
	[6, "Sat"],
]);

export const Days = new Map([
	[0, "Sunday"],
	[1, "Monday"],
	[2, "Tuesday"],
	[3, "Wednesday"],
	[4, "Thursday"],
	[5, "Friday"],
	[6, "Saturday"],
]);

export const ShortMonths = new Map([
	[0, "Jan"],
	[1, "Feb"],
	[2, "Mar"],
	[3, "Apr"],
	[4, "May"],
	[5, "Jun"],
	[6, "Jul"],
	[7, "Aug"],
	[8, "Sep"],
	[9, "Oct"],
	[10, "Nov"],
	[11, "Dec"],
]);

export const Months = new Map([
	[0, "January"],
	[1, "February"],
	[2, "March"],
	[3, "April"],
	[4, "May"],
	[5, "June"],
	[6, "July"],
	[7, "August"],
	[8, "September"],
	[9, "October"],
	[10, "November"],
	[11, "December"],
]);

export default CalendarFormat;
