import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import useWindowSize from "hooks/use-window-size";
import { formatCurrency } from "common/format-currency-pattern";
import AppTable from "components/app-table";
import AppCloseIcon from "components/icons/app-close-icon";

export const AppInvoiceItemsModalTable = (props, ref) => {
	const [width] = useWindowSize();
	const columnHelper = useMemo(() => createColumnHelper(), []);
	const [data, setData] = useState([]);
	const [visible, setVisible] = useState(false);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
        setData(obj);

        setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		columnHelper.accessor("item", {
			header: "Name",
			cell: (c) => c.getValue() ?? "-",
		}),
		columnHelper.accessor("quantity", {
			header: "Quantity",
		}),
		columnHelper.accessor("costPerUnit", {
			header: (h) => `Cost Per Unit`,
			cell: (c) => formatCurrency(c.getValue())
		}),
		columnHelper.accessor("subtotal", {
			header: "SubTotal",
			cell: (c) => formatCurrency(c.getValue())
		}),
	], [columnHelper]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-invoice-items-modal-table" }} open={visible} onClose={onHandleDismiss} aria-labelledby="invoice-items-modal-table" aria-describedby="invoice-items-modal-table">
			<div className="invoice-items-modal-table">
				<div className="invoice-items-modal-table__header">
					<button type="button" className="invoice-items-modal-table__close-button" onClick={onHandleDismiss}>
						<AppCloseIcon />
					</button>
				</div>

				<div className="invoice-items-modal-table__body">
					<AppTable columns={columns} data={data} pageSize={width <= 640 ? 7 : 5} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInvoiceItemsModalTable));

AppInvoiceItemsModalTable.propTypes = {
	ref: PropTypes.object,
};
