import countries from "common/countries";

const getMobileNoPrefix = (string) => {
	let prefixNo = "";

	countries.forEach((a) => {
		a.countryCallingCodes.forEach((b) => {
			if (string.includes(b)) prefixNo = b;
		});
	});

	const mobileNo = string.replaceAll(prefixNo, "");

	return { number: mobileNo, countryCode: prefixNo };
};

export default getMobileNoPrefix;
