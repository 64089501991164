import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useMemo, useRef, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import moment from "moment";

import api from "services/api";
import pathnames from "routes/pathnames";
import downloadFile from "common/download-file";
import { CALENDAR_FORMAT, formatDatePattern, formatDateTime } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import capitalizeCharacter from "common/capitalize-character";
import { formatCurrency } from "common/format-currency-pattern";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppCloseIcon from "components/icons/app-close-icon";
import AppInvoiceItemsModalTable from "components/pages/page-dashboard/app-invoice-items-modal-table";
import editIcon from "assets/images/edit-icon.svg";
import downloadIcon from "assets/images/export-icon.svg";
import AppChevronIcon from "components/icons/app-chevron-icon";

export const AppInvoiceModal = (props, ref) => {
	const navigate = useNavigate();
	const invoiceItemsModalTableRef = useRef();
	const [data, setData] = useState();
	const [visible, setVisible] = useState(false);
	const [downloadProgress, setDownloadProgress] = useState(0);
	const createdBy = useMemo(() => {
		if (!data) return "-";

		const createdBy = capitalizeCharacter(data?.createdBy);
		const date = formatDateTime(new Date());

		return `${createdBy}, ${date}`;
	}, [data]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (id) => {
		let response = null;

		try {
            response = await api.get.invoice.invoice(id);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) setData(response.data);
	}, []);

	//prettier-ignore
	const onHandleDownloadInvoice = useCallback(async () => {
		let response = null;

		try {
			const onDownloadProgress = (progressEvent) => {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

				setDownloadProgress(percentCompleted);
			};

            response = await api.get.invoice.download(data.id, { onDownloadProgress });
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const today = moment().format([CALENDAR_FORMAT.DATE_FORMAT, CALENDAR_FORMAT.MONTH_SHORT_FORMAT, CALENDAR_FORMAT.YEAR_FORMAT].join("-"));
			const fileName =  ["invoice", data.id, today].join("-");
			downloadFile(response, fileName);
		}
	}, [data]);

	//prettier-ignore
	const onHandleShow = useCallback((id) => {
        setVisible(true);
		if (id) onHandleGetDetails(id);
	}, [onHandleGetDetails]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleAccordion = useCallback((event) => {
		const item = event.currentTarget;

		item.nextSibling.classList.toggle("invoice-modal__content--hide");

		item.classList.toggle("invoice-modal__label--active");
	}, []);

	const onHandleDisplayInvoiceItems = useCallback(() => {
		invoiceItemsModalTableRef.current.onHandleShow(data.invoiceProperties.items);
	}, [data]);

	const onHandleEdit = useCallback(() => {
		navigate(pathnames.invoice.createEdit + data.id);
	}, [navigate, data]);

	//prettier-ignore
	const generalContent = useMemo(() => {
		if (!data) return [];

		const content = [
			{ label: "Reference No.", value: data.uniqueReferenceNumber },
			{ label: "Name", value: data.invoiceProperties.client.name },
			{ label: "Email Address", value: data.invoiceProperties.client.email },
			{ label: "Mobile No.", value: data.invoiceProperties.client.phoneNumber },
			{ label: "Address", value: Object.keys(data.invoiceProperties.client.address).map(((o) => data.invoiceProperties.client.address[o] || "")).join(", ") },
		];

		return content;
	}, [data]);

	//prettier-ignore
	const issuerContent = useMemo(() => {
		if (!data) return [];

		const content = [
			{ label: "Company Registration No.", value: data.invoiceProperties.issuer.companyRegistrationNumber },
			{ label: "TIN", value: data.invoiceProperties.issuer.taxIdentificationNumber },
			{ label: "SST No.", value: data.invoiceProperties.issuer.sstRegistrationNumber },
			{ label: "Name", value: data.invoiceProperties.issuer.name },
			{ label: "Email Address", value: data.invoiceProperties.issuer.email },
			{ label: "Mobile No.", value: data.invoiceProperties.issuer.phoneNumber },
			{ label: "Address", value: Object.keys(data.invoiceProperties.issuer.address).map(((o) => data.invoiceProperties.issuer.address[o] || "")).join(", ") },
		];

		return content;
	}, [data]);

	const invoiceContent = useMemo(() => {
		if (!data) return [];

		const content = [
			{ label: "Invoice Date", value: formatDatePattern(data.invoiceProperties.invoiceDate) },
			{ label: "Invoice No.", value: data.invoiceProperties.invoiceNumber || "-" },
			{ label: "Invoice Currency", value: data.invoiceProperties.currencyCode },
			{ label: "Invoice Tax", value: formatCurrency(data.invoiceProperties.tax) },
			{ label: "Invoice Discount", value: formatCurrency(data.invoiceProperties.discount) },
			{ label: "Invoice Items", value: "Show Table", link: true },
			{ label: "Total", value: formatCurrency(data.invoiceProperties.total) },
		];

		return content;
	}, [data]);

	const emailTemplateContent = useMemo(() => {
		if (!data) return [];

		const content = [
			{ label: "Template", value: data.template },
			{ label: "Recipient Email Address", value: data.emailProperties.recipient },
			{ label: "Email Subject", value: data.emailProperties.subject },
			{ label: "Sender Name", value: data.emailProperties.senderName },
			{ label: "CC Email", value: data.emailProperties.cc.join(", ") },
		];

		return content;
	}, [data]);

	const paymentContent = useMemo(() => {
		if (!data) return [];

		const content = [
			{ label: "Purchase Date", value: formatDatePattern(data.invoiceProperties.purchaseDate) },
			{ label: "Payment Due Date", value: formatDatePattern(data.invoiceProperties.paymentDueDate) },
			{ label: "Purchase No.", value: data.invoiceProperties.purchaseNumber },
			{ label: "Account No.", value: data.invoiceProperties.payment[0] },
			{ label: "Reference No.", value: data.invoiceProperties.payment[1] },
			{ label: "Bank", value: data.invoiceProperties.payment[2] },
		];

		return content;
	}, [data]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Fragment>
			<Modal classes={{ root: "app-invoice-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="invoice-modal" aria-describedby="invoice-modal">
				<div className="invoice-modal">
					<div className="invoice-modal__header">
						<AppStatus status={data?.status || ""} />

						<button type="button" className="invoice-modal__close-button" onClick={onHandleDismiss}>
							<AppCloseIcon />
						</button>
					</div>

					<div className="invoice-modal__headline">
						<div className="invoice-modal__wrapper">
							<p className="invoice-modal__title">{[data?.invoiceProperties?.client?.name, data?.uniqueReferenceNumber].filter((o) => o).join(", ")}</p>

							<p className="invoice-modal__created-by">Created By {createdBy}</p>
						</div>
						<div className="invoice-modal__wrapper invoice-modal__wrapper--row">
							<AppButton className="invoice-modal__download-button" outline type="button" label="Download" icon={downloadIcon} onClick={onHandleDownloadInvoice} />

							<button type="button" className="invoice-modal__edit-button" onClick={onHandleEdit}>
								<img src={editIcon} alt="edit icon" />
							</button>
						</div>

						<div className="invoice-modal__download-progress" style={{ width: `${downloadProgress}%` }} />
					</div>

					<div className="invoice-modal__body">
						<p className="invoice-modal__label" onClick={onHandleAccordion}>
							General
							<span className="invoice-modal__icon">
								<AppChevronIcon />
							</span>
						</p>

						<ul className="invoice-modal__content">
							{generalContent.map((o) => {
								return (
									<li className="invoice-modal__item" key={o.label}>
										<p className="invoice-modal__text">{o.label}</p>
										<p className="invoice-modal__description">{o.value}</p>
									</li>
								);
							})}
						</ul>

						<p className="invoice-modal__label" onClick={onHandleAccordion}>
							Issuer
							<span className="invoice-modal__icon">
								<AppChevronIcon />
							</span>
						</p>

						<ul className="invoice-modal__content">
							{issuerContent.map((o) => {
								return (
									<li className="invoice-modal__item" key={o.label}>
										<p className="invoice-modal__text">{o.label}</p>
										<p className="invoice-modal__description">{o.value}</p>
									</li>
								);
							})}
						</ul>

						<p className="invoice-modal__label" onClick={onHandleAccordion}>
							Invoice
							<span className="invoice-modal__icon">
								<AppChevronIcon />
							</span>
						</p>

						<ul className="invoice-modal__content">
							{invoiceContent.map((o) => {
								return (
									<li className="invoice-modal__item" key={o.label}>
										<p className="invoice-modal__text">{o.label}</p>
										{!o.link && <p className="invoice-modal__description">{o.value}</p>}
										{o.link && (
											<button type="button" className="invoice-modal__link" onClick={onHandleDisplayInvoiceItems}>
												{o.value}
											</button>
										)}
									</li>
								);
							})}
						</ul>

						<p className="invoice-modal__label" onClick={onHandleAccordion}>
							Payment
							<span className="invoice-modal__icon">
								<AppChevronIcon />
							</span>
						</p>

						<ul className="invoice-modal__content">
							{paymentContent.map((o) => {
								return (
									<li className="invoice-modal__item" key={o.label}>
										<p className="invoice-modal__text">{o.label}</p>
										<p className="invoice-modal__description">{o.value}</p>
									</li>
								);
							})}
						</ul>

						<p className="invoice-modal__label" onClick={onHandleAccordion}>
							Email Template
							<span className="invoice-modal__icon">
								<AppChevronIcon />
							</span>
						</p>

						<ul className="invoice-modal__content">
							{emailTemplateContent.map((o) => {
								return (
									<li className="invoice-modal__item" key={o.label}>
										<p className="invoice-modal__text">{o.label}</p>
										<p className="invoice-modal__description">{o.value}</p>
									</li>
								);
							})}
						</ul>

						<p className="invoice-modal__label" onClick={onHandleAccordion}>
							Remark
							<span className="invoice-modal__icon">
								<AppChevronIcon />
							</span>
						</p>

						<p className="invoice-modal__description">{data?.invoiceProperties?.notes?.[0] || "-"}</p>
					</div>
				</div>
			</Modal>

			<AppInvoiceItemsModalTable ref={invoiceItemsModalTableRef} />
		</Fragment>
	);
};

export default memo(forwardRef(AppInvoiceModal));

AppInvoiceModal.propTypes = {
	ref: PropTypes.object,
};
