import addDays from "./add-days";
import getStartDate from "./get-start-date";
import getFirstCellDate from "./get-first-cell-date";
import { Days, ShortDays, Months, ShortMonths } from "./calendar-format";
import validateSameDate from "./validate-same-date";

const generateDates = (date, datePattern) => {
	let totalDate = 0;

	const totalCell = 42;

	const dates = [];

	const startDate = getStartDate(date);

	const separater = datePattern ?? "-";

	const firstDate = getFirstCellDate(startDate);

	while (totalDate < totalCell) {
		/* avoid overwriting "firstDate" const */
		const newInstanceOfDate = new Date(firstDate);

		const dateInstance = addDays(newInstanceOfDate, totalDate);

		const year = dateInstance.getFullYear();

		const month = dateInstance.getMonth();

		const day = dateInstance.getDay();

		const nextDate = dateInstance.getDate();

		const objDate = { fullDate: nextDate + separater + (month + 1) + separater + year, date: nextDate };

		const objMonth = { month, shortMonth: ShortMonths.get(month), longMonth: Months.get(month) };

		const objDay = { day, shortDay: ShortDays.get(day), longDay: Days.get(day) };

		const today = validateSameDate(new Date(), dateInstance);

		const sameMonth = date.getMonth() === month;

		const newDate = { _d: dateInstance, date: objDate, month: objMonth, year, day: objDay, today: today, sameMonth };

		dates.push(newDate);

		totalDate++;
	}

	return dates;
};

export default generateDates;
