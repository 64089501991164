import React, { useCallback, useEffect, useMemo } from "react";
import { FormikProvider, useFormik, FieldArray, setNestedObjectValues } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Switch from "@mui/material/Switch";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import OPTIONS from "common/options";
import { formatDateTime } from "common/calendar";
import { promptAlertMessage } from "store/slices/alert";
import getOrdinalNumber from "common/get-ordinal-number";
import getMobileNoPrefix from "common/get-mobile-no-prefix";
import serveRequestErrors from "common/serve-request-errors";
import formatCurrencyPattern, { formatCurrency } from "common/format-currency-pattern";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppMobileInput from "components/app-mobile-input";
import AppUnicornCalendarInput from "components/app-calendar-input-2";
import deleteIcon from "assets/images/remove-icon.svg";
import addIcon from "assets/images/pages/invoice/add-green-icon.svg";

const PageInvoiceCreateEdit = (props) => {
	let { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isCreate = useMemo(() => id === COMMON.PAGE.CREATE, [id]);
	const invoiceItems = useMemo(() => ({ item: "", costPerUnit: formatCurrency("0"), quantity: "1", subtotal: "" }), []);
	//prettier-ignore
	const initialValues = useMemo(() => ({
		template: OPTIONS.TEMPLATES[0].value,
		recipientEmail: "",
		emailSubject: "",
		ccEmail: [""],
		senderEmail: "noreply@altecflex.com",
		senderName: "noreply@altecflex.com",
		name: "",
		email: "",
		mobileNo: "",
		referenceNo: "",
		adddress1: "",
		adddress2: "",
		state: "",
		city: "",
		postcode: "",
		companyName: "",
		companyEmail: "",
		companyMobileNo: "",
		companyPrefixMobileNo: "",
		companyRegistrationNo: "",
		companyAdddress1: "",
		companyAdddress2: "",
		companyState: "",
		companyCity: "",
		companyPostcode: "",
		invoiceNumber: "",
		invoiceDate: undefined,
		invoiceCurrency: "",
		invoiceTax: formatCurrency("0"),
		invoiceDiscount: formatCurrency("0"),
		invoicePurchaseNumber: "",
		invoicePurchaseDate: undefined,
		invoicePaymentDueDate: undefined,
		invoiceTaxIdentificationNo: "",
		invoiceSstIdentificationNo: "",
		invoiceTotal: "",
		invoiceItems: [invoiceItems],
		invoiceTnc: "",
		bank: "",
		bankAccountNo: "",
		bankReference: "",
		remark: "",
		toSendEmail: false,
	}), [invoiceItems]);

	//prettier-ignore
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			template: yup.string().required(ERRORS.REQUIRED),
			emailSubject: yup.string().required(ERRORS.REQUIRED),
			ccEmail: yup.array().of(yup.string().matches(COMMON.REGEX.EMAIL, ERRORS.EMAIL)),
			recipientEmail: yup.string().required(ERRORS.REQUIRED),
			name: yup.string().required(ERRORS.REQUIRED),
			referenceNo: yup.string().required(ERRORS.REQUIRED),
			mobileNo: yup.string().required(ERRORS.REQUIRED),
			email: yup.string().matches(COMMON.REGEX.EMAIL, ERRORS.EMAIL).required(ERRORS.REQUIRED),
			adddress1: yup.string().required(ERRORS.REQUIRED),
			state: yup.string().required(ERRORS.REQUIRED),
			city: yup.string().required(ERRORS.REQUIRED),
			postcode: yup.string().required(ERRORS.REQUIRED),
			companyName: yup.string().required(ERRORS.REQUIRED),
			companyRegistrationNo: yup.string().required(ERRORS.REQUIRED),
			companyMobileNo: yup.string().required(ERRORS.REQUIRED),
			companyEmail: yup.string().matches(COMMON.REGEX.EMAIL, ERRORS.EMAIL).required(ERRORS.REQUIRED),
			companyAdddress1: yup.string().required(ERRORS.REQUIRED),
			companyState: yup.string().required(ERRORS.REQUIRED),
			companyCity: yup.string().required(ERRORS.REQUIRED),
			companyPostcode: yup.string().required(ERRORS.REQUIRED),
			invoiceNumber: yup.string().required(ERRORS.REQUIRED),
			invoiceDate: yup.string().required(ERRORS.REQUIRED),
			invoicePurchaseNumber: yup.string().required(ERRORS.REQUIRED),
			invoicePurchaseDate: yup.string().required(ERRORS.REQUIRED),
			invoicePaymentDueDate: yup.string().required(ERRORS.REQUIRED),
			invoiceTaxIdentificationNo: yup.string().required(ERRORS.REQUIRED),
			invoiceSstIdentificationNo: yup.string().required(ERRORS.REQUIRED),
			invoiceTax: yup.string().required(ERRORS.REQUIRED),
			invoiceCurrency: yup.string().required(ERRORS.REQUIRED),
			invoiceItems: yup.array().of(yup.object({ item: yup.string().required(ERRORS.REQUIRED), costPerUnit: yup.string().required(ERRORS.REQUIRED), quantity: yup.string().required(ERRORS.REQUIRED) })).min(1).required(ERRORS.REQUIRED),
			invoiceTnc: yup.string().url(),
			bank: yup.string().required(ERRORS.REQUIRED),
			bankAccountNo: yup.string().required(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const submitLabel = useMemo(() => (isCreate ? "CREATE" : "UPDATE"), [isCreate]);
	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);
	const onHandleCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const totalInvoiceAmount = useMemo(() => {
		const items = formik.values.invoiceItems;
		const tax = Number(formik.values.invoiceTax.replaceAll(",", ""));
		const discount = Number(formik.values.invoiceDiscount.replaceAll(",", ""));

		const totalItems = items.reduce((a, b) => (Number(b.costPerUnit?.replaceAll(",", "") || 0) * b.quantity || 1) + a, 0);

		return formatCurrency(totalItems - tax - discount);
	}, [formik.values]);

	const onHandleCancel = () => {
		navigate(-1);
	};

	const onHandleSubmit = async (values) => {
		let response = null;
		try {
			const payload = {
				type: "INVOICE",
				template: values.template,
				email: {
					recipient: values.recipientEmail,
					cc: values.ccEmail,
					subject: values.emailSubject,
					sender: values.senderEmail,
					senderName: values.senderName,
					secret: "hywq4m70ppKK",
				},
				invoice: {
					invoiceNumber: values.invoiceNumber,
					invoiceDate: values.invoiceDate?.toISOString(),
					purchaseNumber: values.invoicePurchaseNumber,
					purchaseDate: values.invoicePurchaseDate?.toISOString(),
					paymentDueDate: values.invoicePaymentDueDate?.toISOString(),
					tax: Number(values.invoiceTax.replaceAll(",", "")),
					discount: Number(values.invoiceDiscount.replaceAll(",", "")),
					total: Number(totalInvoiceAmount.replaceAll(",", "")),
					currencyCode: values.invoiceCurrency,
					issuer: {
						logo: "",
						logoWidth: "",
						logoHeight: "",
						name: values.companyName,
						companyRegistrationNumber: values.companyRegistrationNo,
						taxIdentificationNumber: values.invoiceTaxIdentificationNo,
						sstRegistrationNumber: values.invoiceSstIdentificationNo,
						address: {
							adddressOne: values.companyAdddress1,
							adddressTwo: values.companyAdddress2,
							state: values.companyState,
							city: values.companyCity,
							postcode: values.companyPostcode,
						},
						phoneNumber: values.companyPrefixMobileNo + values.companyMobileNo,
						officeNumber: values.officeNumber,
						email: values.companyEmail,
					},
					client: {
						name: values.name,
						phoneNumber: values.prefixNo + values.mobileNo,
						email: values.email,
						address: {
							adddressOne: values.adddress1,
							adddressTwo: values.adddress2,
							state: values.state,
							city: values.city,
							postcode: values.postcode,
						},
					},
					items: values.invoiceItems.map((o) => ({ ...o, costPerUnit: Number(o.costPerUnit.replaceAll(",", "")), subtotal: Number(o.quantity || 1) * (Number(o.costPerUnit.replaceAll(",", "")) || 0) })),
					payment: [values.bank, values.bankAccountNo, values.bankReference],
					notes: [values.remark],
					tnc: values.invoiceTnc,
					themeColor: "",
					fontColor: "",
				},
				uniqueReferenceNumber: values.referenceNo,
				toSendEmail: values.toSendEmail,
			};

			if (!isCreate) payload.id = id;

			response = await api.post.invoice.create(payload);
		} catch (error) {
			serveRequestErrors(error);

			formik.setSubmitting(false);
		}
		if (response) {
			dispatch(promptAlertMessage({ message: "Invoice has been created." }));
			navigate(-1);
		}
	};

	const onHandleAddCcEmail = useCallback(async (obj) => {
		if (obj.form.values.ccEmail.length >= 10) return;

		const fieldErrors = await obj.form.validateForm();

		const fieldIsEmpty = obj.form.values.ccEmail.some((o) => !o);

		if (fieldErrors.ccEmail) {
			obj.form.setTouched(setNestedObjectValues({ ccEmail: fieldErrors.ccEmail }, true));
		} else if (!fieldIsEmpty) {
			obj.push("");
		}
	}, []);

	//prettier-ignore
	const onHandleAddInvoiceItem = useCallback(async (obj) => {
		const fieldErrors = await obj.form.validateForm();

		if (fieldErrors.invoiceItems) {
			obj.form.setTouched(setNestedObjectValues({ invoiceItems: fieldErrors.invoiceItems }, true));
		} else {
			obj.push(invoiceItems);
		}
	}, [invoiceItems]);

	const CreateEditBy = useCallback((obj) => {
		if (obj.isCreate) {
			return <p className="invoice-create-edit__date">Today: {formatDateTime(new Date())}</p>;
		}

		return (
			<p className="invoice-create-edit__date">
				Last updated by: {obj.values.lastModifiedByName}, {formatDateTime(new Date(obj.values.lastModifiedDate))}{" "}
			</p>
		);
	}, []);

	useEffect(() => {
		//prettier-ignore
		const onHandleGetDetails = async (id) => {
			let response = null;

			try {
				response = await api.get.invoice.invoice(id);
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) {
				const mobileNo = getMobileNoPrefix(response.data.invoiceProperties.client.phoneNumber);
				const companyMobileNo = getMobileNoPrefix(response.data.invoiceProperties.issuer.phoneNumber);

				memoSetFormValues({
					template: response.data.template,
					recipientEmail: response.data.emailProperties.recipient,
					emailSubject: response.data.emailProperties.subject,
					ccEmail: response.data.emailProperties.cc,
					senderEmail: response.data.emailProperties.sender,
					senderName: response.data.emailProperties.senderName,
					name: response.data.invoiceProperties.client.name,
					email: response.data.invoiceProperties.client.email,
					prefixNo: mobileNo.countryCode,
					mobileNo: mobileNo.number,
					referenceNo: response.data.uniqueReferenceNumber,
					adddress1: response.data.invoiceProperties.client.address.adddressOne,
					adddress2: response.data.invoiceProperties.client.address.adddressTwo,
					state: response.data.invoiceProperties.client.address.state,
					city: response.data.invoiceProperties.client.address.city,
					postcode: response.data.invoiceProperties.client.address.postcode,
					companyName: response.data.invoiceProperties.issuer.name,
					companyEmail: response.data.invoiceProperties.issuer.email,
					companyMobileNo: companyMobileNo.number,
					companyPrefixMobileNo: companyMobileNo.countryCode,
					companyRegistrationNo: response.data.invoiceProperties.issuer.companyRegistrationNumber,
					companyAdddress1: response.data.invoiceProperties.issuer.address.adddressOne,
					companyAdddress2: response.data.invoiceProperties.issuer.address.adddressTwo,
					companyState: response.data.invoiceProperties.issuer.address.state,
					companyCity: response.data.invoiceProperties.issuer.address.city,
					companyPostcode: response.data.invoiceProperties.issuer.address.postcode,
					invoiceNumber: response.data.invoiceProperties.invoiceNumber,
					invoiceDate: new Date(response.data.invoiceProperties.invoiceDate),
					invoiceCurrency: response.data.invoiceProperties.currencyCode,
					invoiceTax: formatCurrency(response.data.invoiceProperties.tax),
					invoiceDiscount: formatCurrency(response.data.invoiceProperties.discount),
					invoicePurchaseNumber: response.data.invoiceProperties.purchaseNumber,
					invoicePurchaseDate: new Date(response.data.invoiceProperties.purchaseDate),
					invoicePaymentDueDate: new Date(response.data.invoiceProperties.paymentDueDate),
					invoiceTaxIdentificationNo: response.data.invoiceProperties.issuer.taxIdentificationNumber,
					invoiceSstIdentificationNo: response.data.invoiceProperties.issuer.sstRegistrationNumber,
					invoiceTotal: "",
					invoiceItems: response.data.invoiceProperties.items,
					invoiceTnc: response.data.invoiceProperties.tnc,
					bank: response.data.invoiceProperties.payment[0],
					bankAccountNo: response.data.invoiceProperties.payment[1],
					bankReference: response.data.invoiceProperties.payment[2],
					remark: response.data.invoiceProperties.notes[0],
					toSendEmail: false,
					lastModifiedByName: response.data.updatedBy,
					lastModifiedDate: response.data.updatedAt
				});
			}
		};

		if (id && !isCreate) onHandleGetDetails(id);
	}, [id, isCreate, memoSetFormValues]);

	useEffect(() => {
		return () => {
			onHandleCancelRequest(COMMON.ENDPOINT_PATH.INVOICE.INVOICE);
		};
	}, [onHandleCancelRequest]);

	return (
		<FormikProvider value={formik}>
			<div className="page-invoice-create-edit">
				<div className="invoice-create-edit">
					<h1 className="invoice-create-edit__name">Create E-Invoice</h1>

					<CreateEditBy isCreate={isCreate} values={formik.values} />

					<form className="invoice-create-edit__form" onSubmit={formik.handleSubmit}>
						<div className="invoice-create-edit__container">
							<div className="invoice-create-edit__box">
								<div className="invoice-create-edit__wrapper">
									<p className="invoice-create-edit__label">Issuer</p>

									<div className="invoice-create-edit__row">
										{/* prettier-ignore */}
										<AppInput required type="text" name="companyName" label="Company Name" placeholder="Please Enter Company Name" value={formik.values.companyName} error={formik.errors.companyName} touched={formik.touched.companyName} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput required type="text" name="companyRegistrationNo" label="Company Registration No." placeholder="Please Enter Company Registration No." value={formik.values.companyRegistrationNo} error={formik.errors.companyRegistrationNo} touched={formik.touched.companyRegistrationNo} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput required type="text" name="companyEmail" label="Company Email Address" placeholder="Please Enter Company Email Address" value={formik.values.companyEmail} error={formik.errors.companyEmail} touched={formik.touched.companyEmail} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppMobileInput required type="text" name="companyMobileNo" prefixNoName="companyPrefixMobileNo" label="Company Mobile No." placeholder="Eg: 103876929" value={formik.values.companyMobileNo} prefixNo={formik.values.companyPrefixMobileNo} error={formik.errors.companyMobileNo} touched={formik.touched.companyMobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

										{/* prettier-ignore */}
										<AppInput required type="text" name="companyAdddress1" label="Company Address 1" placeholder="Please Enter Company Address 1" value={formik.values.companyAdddress1} error={formik.errors.companyAdddress1} touched={formik.touched.companyAdddress1} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput type="text" name="companyAdddress2" label="Company Address 2" placeholder="Please Enter Company Address 2" value={formik.values.companyAdddress2} error={formik.errors.companyAdddress2} touched={formik.touched.companyAdddress2} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput required type="text" name="companyState" label="Company State" placeholder="Please Enter Company State" value={formik.values.companyState} error={formik.errors.companyState} touched={formik.touched.companyState} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput required type="text" name="companyCity" label="Company City" placeholder="Please Enter Company City" value={formik.values.companyCity} error={formik.errors.companyCity} touched={formik.touched.companyCity} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput required type="number" name="companyPostcode" label="Company Postcode" placeholder="Please Enter Postcode" value={formik.values.companyPostcode} error={formik.errors.companyPostcode} touched={formik.touched.companyPostcode} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput required type="text" name="invoiceTaxIdentificationNo" label="TIN" placeholder="Please Enter TIN" value={formik.values.invoiceTaxIdentificationNo} error={formik.errors.invoiceTaxIdentificationNo} touched={formik.touched.invoiceTaxIdentificationNo} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput required type="text" name="invoiceSstIdentificationNo" label="SST No." placeholder="Please Enter SST" value={formik.values.invoiceSstIdentificationNo} error={formik.errors.invoiceSstIdentificationNo} touched={formik.touched.invoiceSstIdentificationNo} onChange={formik.handleChange} />
									</div>

									<p className="invoice-create-edit__label">Client Details</p>

									<div className="invoice-create-edit__row">
										{/* prettier-ignore */}
										<AppInput required type="text" name="referenceNo" label="Reference No." placeholder="Eg: C-2028A" value={formik.values.referenceNo} error={formik.errors.referenceNo} touched={formik.touched.referenceNo} onChange={formik.handleChange} onChangeCode={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput required type="text" name="name" label="Full Name" placeholder="Please Enter Full Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput required type="text" name="email" label="Email Address" placeholder="Please Enter Email Address" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppMobileInput required type="text" name="mobileNo" label="Mobile No." placeholder="Eg: 103876929" value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />
									</div>

									<div className="invoice-create-edit__row">
										{/* prettier-ignore */}
										<AppInput required type="text" name="adddress1" label="Address 1" placeholder="Please Enter Address 1" value={formik.values.adddress1} error={formik.errors.adddress1} touched={formik.touched.adddress1} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput type="text" name="adddress2" label="Address 2" placeholder="Please Enter Address 2" value={formik.values.adddress2} error={formik.errors.adddress2} touched={formik.touched.adddress2} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput required type="text" name="state" label="State" placeholder="Please Enter State" value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput required type="text" name="city" label="City" placeholder="Please Enter City" value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput required type="number" name="postcode" label="Postcode" placeholder="Please Enter Postcode" value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
									</div>

									<p className="invoice-create-edit__label">Invoice</p>

									<div className="invoice-create-edit__row">
										{/* prettier-ignore */}
										<AppInput required type="text" name="invoiceNumber" label="Invoice Number" placeholder="Please Enter Invoice Number" value={formik.values.invoiceNumber} error={formik.errors.invoiceNumber} touched={formik.touched.invoiceNumber} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput required type="text" name="invoicePurchaseNumber" label="Invoice Purchase No." placeholder="Please Enter Purchase No." value={formik.values.invoicePurchaseNumber} error={formik.errors.invoicePurchaseNumber} touched={formik.touched.invoicePurchaseNumber} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppUnicornCalendarInput required name="invoiceDate" label="Invoice Date" placeholder="Please Select Invoice Date" value={formik.values.invoiceDate} error={formik.errors.invoiceDate} touched={formik.touched.invoiceDate} onChange={formik.setFieldValue} />

										{/* prettier-ignore */}
										<AppUnicornCalendarInput required name="invoicePurchaseDate" label="Invoice Purchase Date" placeholder="Please Select Invoice Purchase Date" value={formik.values.invoicePurchaseDate} error={formik.errors.invoicePurchaseDate} touched={formik.touched.invoicePurchaseDate} onChange={formik.setFieldValue} />

										{/* prettier-ignore */}
										<AppUnicornCalendarInput required name="invoicePaymentDueDate" label="Invoice Payment Due Date" placeholder="Please Select Invoice Payment Due Date" value={formik.values.invoicePaymentDueDate} error={formik.errors.invoicePaymentDueDate} touched={formik.touched.invoicePaymentDueDate} onChange={formik.setFieldValue} />

										{/* prettier-ignore */}
										<AppInput type="text" name="invoiceTnc" label="Invoice Tnc" placeholder="Please Enter Invoice Tnc" value={formik.values.invoiceTnc} error={formik.errors.invoiceTnc} touched={formik.touched.invoiceTnc} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppSelectInput required type="text" name="invoiceCurrency" label="Invoice Currency" placeholder="Please Enter Invoice Currency" options={OPTIONS.CURRENCY} value={formik.values.invoiceCurrency} error={formik.errors.invoiceCurrency} touched={formik.touched.invoiceCurrency} onChange={formik.handleChange} />
									</div>

									<FieldArray
										name="invoiceItems"
										render={(f) => {
											const values = f.form.values?.invoiceItems;
											const errors = f.form.errors?.invoiceItems;
											const touched = f.form.touched?.invoiceItems;

											return (
												<div className="invoice-create-edit__column invoice-create-edit__column--divider">
													<div className="invoice-create-edit__add-more-button">
														<AppButton type="button" outline icon={addIcon} label="Add invoice item" onClick={() => onHandleAddInvoiceItem(f)} />
													</div>

													<div className="invoice-create-edit__row">
														{values.map((o, i) => {
															const number = getOrdinalNumber(i + 1);
															const removeable = values.length > 1;
															const subTotal = formatCurrency((o.quantity || 1) * (Number(o.costPerUnit.replaceAll(",", "")) || 0));

															return (
																<div className="invoice-create-edit__column" key={i}>
																	<p className="invoice-create-edit__label">
																		{number} Invoice Item{" "}
																		{removeable && (
																			<span className="invoice-create-edit__delete" onClick={() => f.remove(i)}>
																				<img src={deleteIcon} alt="delete" />
																			</span>
																		)}
																	</p>

																	<div className="invoice-create-edit__row invoice-create-edit__row--divider">
																		{/* prettier-ignore */}
																		<AppInput required type="text" name={`invoiceItems.${i}.item`} label="Name" placeholder="Please Enter Name" value={o.item} error={errors?.[i]?.item} touched={touched?.[i]?.item} onChange={formik.handleChange} />
																		{/* prettier-ignore */}
																		<AppInput required type="text" name={`invoiceItems.${i}.costPerUnit`} label="Cost Per Unit" placeholder="Please Enter Cost Per Unit" value={o.costPerUnit} error={errors?.[i]?.costPerUnit} touched={touched?.[i]?.costPerUnit} onChange={formik.handleChange} onFormat={formatCurrencyPattern}/>
																		{/* prettier-ignore */}
																		<AppInput required type="number" name={`invoiceItems.${i}.quantity`} label="Quantity" placeholder="Please Enter Quantity" value={o.quantity} error={errors?.[i]?.quantity} touched={touched?.[i]?.quantity} onChange={formik.handleChange} />
																		{/* prettier-ignore */}
																		<AppInput disabled type="text" name={`invoiceItems.${i}.subtotal`} label="Sub Total" placeholder="Please Enter Sub Total" value={subTotal} onChange={formik.handleChange} />
																	</div>
																</div>
															);
														})}
													</div>
												</div>
											);
										}}
									/>

									<div className="invoice-create-edit__row">
										{/* prettier-ignore */}
										<AppInput required type="text" name="invoiceTax" label="Invoice Tax" placeholder="Please Enter Invoice Tax" value={formik.values.invoiceTax} error={formik.errors.invoiceTax} touched={formik.touched.invoiceTax} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

										{/* prettier-ignore */}
										<AppInput type="text" name="invoiceDiscount" label="Invoice Discount" placeholder="Please Enter Discount" value={formik.values.invoiceDiscount} error={formik.errors.invoiceDiscount} touched={formik.touched.invoiceDiscount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

										{/* prettier-ignore */}
										<AppInput disabled type="number" name="invoiceTotal" label="Invoice Total" placeholder="Please Enter Total" value={totalInvoiceAmount} onChange={formik.handleChange} />
									</div>

									<p className="invoice-create-edit__label">Invoice Payment Details</p>

									<div className="invoice-create-edit__row">
										{/* prettier-ignore */}
										<AppInput required type="text" name="bank" label="Bank" placeholder="Please Enter Bank" value={formik.values.bank} error={formik.errors.bank} touched={formik.touched.bank} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput required type="text" name="bankAccountNo" label="Bank Account No." placeholder="Please Enter Bank Account No." value={formik.values.bankAccountNo} error={formik.errors.bankAccountNo} touched={formik.touched.bankAccountNo} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput type="text" name="bankReference" label="Bank Reference" placeholder="Please Enter Bank Reference" value={formik.values.bankReference} error={formik.errors.bankReference} touched={formik.touched.bankReference} onChange={formik.handleChange} />
									</div>

									<p className="invoice-create-edit__label">Email Template</p>

									<div className="invoice-create-edit__row">
										{/* prettier-ignore */}
										<AppSelectInput required type="text" name="template" label="Template" placeholder="Please Enter Template" options={OPTIONS.TEMPLATES} value={formik.values.template} error={formik.errors.template} touched={formik.touched.template} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput required type="text" name="recipientEmail" label="Recipient Email Address" placeholder="Please Enter Recipient Email Address" value={formik.values.recipientEmail} error={formik.errors.recipientEmail} touched={formik.touched.recipientEmail} onChange={formik.handleChange} />
									</div>

									<div className="invoice-create-edit__row">
										<FieldArray
											name="ccEmail"
											render={(f) => {
												const values = f.form.values?.ccEmail;
												const errors = f.form.errors?.ccEmail;
												const touched = f.form.touched?.ccEmail;

												return (
													<div className="invoice-create-edit__column">
														<div className="invoice-create-edit__add-more-button">
															<AppButton type="button" outline icon={addIcon} label="Add CC Email" onClick={() => onHandleAddCcEmail(f)} />
														</div>

														<div className="invoice-create-edit__row">
															{values.map((o, i) => {
																const number = getOrdinalNumber(i + 1);
																const removeable = values.length > 1;

																return (
																	<div className="invoice-create-edit__input" key={i}>
																		<label className="app-input__label" htmlFor={props.name}>
																			{`${number} CC Email Address`}

																			{removeable && (
																				<span className="invoice-create-edit__delete" onClick={() => f.remove(i)}>
																					<img src={deleteIcon} alt="delete" />
																				</span>
																			)}
																		</label>

																		{/* prettier-ignore */}
																		<AppInput key={i} type="text" name={`ccEmail.${i}`} label="" placeholder="Please Enter CC Email Address" value={o} error={errors?.[i]} touched={touched?.[i]} onChange={formik.handleChange} />
																	</div>
																);
															})}
														</div>
													</div>
												);
											}}
										/>
									</div>

									<div className="invoice-create-edit__row">
										{/* prettier-ignore */}
										<AppInput required type="text" name="emailSubject" label="Email Subject" placeholder="Please Enter Email Subject" value={formik.values.emailSubject} error={formik.errors.emailSubject} touched={formik.touched.emailSubject} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput disabled type="text" name="senderEmail" label="Sender Email Address" placeholder="Please Enter Sender Email Address" value={formik.values.senderEmail} error={formik.errors.senderEmail} touched={formik.touched.senderEmail} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput disabled type="text" name="senderName" label="Sender Name" placeholder="Please Enter Sender Name" value={formik.values.senderName} error={formik.errors.senderName} touched={formik.touched.senderName} onChange={formik.handleChange} />
									</div>

									<div className="invoice-create-edit__row">
										<div className="invoice-create-edit__send-email">
											<Switch name="toSendEmail" value={formik.values.toSendEmail} onChange={() => formik.setFieldValue("toSendEmail", !formik.values.toSendEmail)} />
											<p className="invoice-create-edit__text">Automatically send an email upon creation.</p>
										</div>
									</div>

									<p className="invoice-create-edit__label">Remark</p>

									<div className="invoice-create-edit__row">
										{/* prettier-ignore */}
										<AppInput multiline maxLength={225} type="text" name="remark" label="Remark" placeholder="Please Enter Remark" value={formik.values.remark} error={formik.errors.remark} touched={formik.touched.remark} onChange={formik.handleChange} />
									</div>
								</div>
							</div>
						</div>

						<div className="invoice-create-edit__button-container">
							<AppButton large outline type="button" label="CANCEL" disabled={formik.isSubmitting} onClick={onHandleCancel} />
							<AppButton large type="submit" label={submitLabel} />
						</div>
					</form>
				</div>
			</div>
		</FormikProvider>
	);
};

export default PageInvoiceCreateEdit;
