import React, { memo, useRef, useContext, useState, useCallback } from "react";

import { AxiosContext } from "contexts/with-interceptor-provider";
import AppAlert from "components/app-alert";
import AppHeader from "components/app-header";
import AppSideNav from "components/app-side-nav";

const AppLayout = ({ children }) => {
	const sideNavRef = useRef();
	const [title, setTitle] = useState("");
	const props = useContext(AxiosContext);

	const onHandleSetTitle = useCallback((text) => {
		setTitle(text);
	}, []);

	const onHandleToggleSideNav = useCallback(() => {
		sideNavRef.current.onHandleToggleSideNav();
	}, []);

	return (
		<div className="app-layout">
			<AppAlert />

			<main className="main">
				<AppSideNav ref={sideNavRef} onHandleSetTitle={onHandleSetTitle} />

				<div id="layout-container" className="main__container">
					<AppHeader title={title} onHandleToggleSideNav={onHandleToggleSideNav} />

					<div className="main__page" id="main-page">{React.cloneElement(children, { ...props })}</div>

					<footer className="main__copyright">Copyright © {new Date().getFullYear()} atf | Altecflex Sdn Bhd. All Rights Reserved.</footer>
				</div>
			</main>
		</div>
	);
};

export default memo(AppLayout);
