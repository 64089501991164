import React, { memo, forwardRef, useState, useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from "@tanstack/react-table";

import AppChevronIcon from "components/icons/app-chevron-icon";
import { ReactComponent as EditIcon } from "assets/images/edit-icon.svg";
import { ReactComponent as ViewIcon } from "assets/images/view-icon.svg";
import { ReactComponent as MoreIcon } from "assets/images/more-icon.svg";
import { ReactComponent as LoadingLogo } from "assets/images/loading-logo.svg";
import classNames from "common/class-names";

export const AppTableEdit = memo((props) => {
	return (
		<button className="table__edit-button" type="button" onClick={props.onClick}>
			<EditIcon />
		</button>
	);
});

export const MoreTableView = memo((props) => {
	return (
		<button className="table__edit-button" type="button" onClick={props.onClick}>
			<MoreIcon />
		</button>
	);
});

export const AppTableView = memo((props) => {
	return (
		<button className="table__edit-button" type="button" onClick={props.onClick}>
			<ViewIcon />
		</button>
	);
});

const AppTable = forwardRef((props, ref) => {
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: props.pageSize ?? 10 });

	const table = useReactTable({
		state: { pagination },
		data: props.data,
		columns: props.columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onPaginationChange: setPagination,
	});

	const isEmptyData = useMemo(() => props.data.length === 0, [props.data]);

	const appClassName = useMemo(() => classNames({ "app-table": true, "app-table--extend": isEmptyData || props.fetching }), [isEmptyData, props.fetching]);
	const tableClassName = useMemo(() => classNames({ table: true, "table--extend": isEmptyData || props.fetching }), [isEmptyData, props.fetching]);

	return (
		<div className={appClassName}>
			<div className="app-table__container">
				<table className={tableClassName} cellSpacing="0" cellPadding="0">
					<thead className="table__thead">
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id} className="table__tr">
								{headerGroup.headers.map((header) => (
									<th key={header.id} className="table__th" colSpan={header.colSpan}>
										{flexRender(header.column.columnDef.header, header.getContext())}
									</th>
								))}
							</tr>
						))}
					</thead>

					<tbody className="table__tbody">
						{!props.fetching &&
							!isEmptyData &&
							table.getRowModel().rows.map((row) => (
								<Fragment key={row.id}>
									<tr className="table__tr">
										{row.getVisibleCells().map((cell) => (
											<td key={cell.id} className="table__td">
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										))}
									</tr>
								</Fragment>
							))}

						{props.fetching && (
							<tr className="table__tr">
								<td className="table__td" colSpan={props.columns.length}>
									<span className="table__empty-state app-loading">
										<LoadingLogo />
									</span>
								</td>
							</tr>
						)}

						{isEmptyData && (
							<tr className="table__tr">
								<td className="table__td" colSpan={props.columns.length}>
									<span className="table__empty-state app-loading">
										<LoadingLogo />

										<span className="table__text">No Data</span>
									</span>
								</td>
							</tr>
						)}
					</tbody>
				</table>

				<div className="pagination">
					<div className="pagination__content">
						<button className="pagination__nav pagination__nav--prev" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
							<AppChevronIcon />
						</button>
						<button className="pagination__nav pagination__nav--next" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
							<AppChevronIcon />
						</button>

						<p className="pagination__text">
							Showing <span>{table.getRowModel().rows.length.toLocaleString()}</span> of {table.getRowCount().toLocaleString()} Rows
						</p>
					</div>

					<div className="pagination__content">
						<p className="pagination__text">
							Page <span>{table.getState().pagination.pageIndex + 1}</span> of {table.getPageCount().toLocaleString()}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
});

AppTable.propTypes = {
	data: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
};

export default memo(AppTable);
