import { memo, useCallback, useMemo, useState } from "react";

import classNames from "common/class-names";
import generatePanelYears, { generateYears, minYear, maxYear } from "./common/generate-years";

import { ReactComponent as ChevronIcon } from "./assets/chevron.svg";

const UnicornYears = (props) => {
	const [displayYears, setDisplayYears] = useState(false);

	const today = useMemo(() => new Date().getFullYear(), []);

	const defaultYear = useMemo(() => props.year === -1, [props.year]);

	const defaultYearDate = useMemo(() => (defaultYear ? today : props.year), [defaultYear, today, props.year]);

	const [selected, setSelected] = useState(defaultYearDate);

	const years = useMemo(() => generatePanelYears(defaultYearDate), [defaultYearDate]);

	const yearList = useMemo(() => generateYears(selected), [selected]);

	const selectedYear = useMemo(() => defaultYearDate, [defaultYearDate]);

	const yearDisplayClassName = useMemo(() => classNames({ years__display: true, years__selected: !defaultYear }), [defaultYear]);

	const navItemClassName = useMemo(() => classNames({ "years__selection-item": true, years__visible: displayYears }), [displayYears]);

	const onHandleDisplayYears = useCallback(() => {
		setDisplayYears((prev) => !prev);

		setSelected(defaultYearDate);
	}, [defaultYearDate]);

	//prettier-ignore
	const onHandleChangeYear = useCallback((y) => {
		setDisplayYears((prev) => !prev);
		
		setSelected(y);

		props.onChange(y);
	}, [props]);

	const onHandlePrevDates = useCallback(() => {
		setSelected((prev) => (prev - 13 < minYear ? minYear : prev - 13));
	}, []);

	const onHandleNextDates = useCallback(() => {
		setSelected((prev) => (prev + 13 > maxYear ? maxYear : prev + 13));
	}, []);

	return (
		<div className="unicorn__years">
			<div className="years">
				<div className="years__header">
					<button type="button" className="years__view-all-button" onClick={onHandleDisplayYears}>
						<p className={yearDisplayClassName}>{selectedYear}</p>
					</button>

					<ul className="years__nav">
						{years.map((y, i) => {
							const sameYearAsToday = today === y;

							const activeYear = !defaultYear && props.year === y;

							const isMinYear = props.minDate ? props.minDate.getFullYear() > y : false;

							const isMaxYear = props.maxDate ? props.maxDate.getFullYear() < y : false;

							const isDisabled = isMinYear || isMaxYear;

							const textClassName = classNames({ years__text: true, "years__text-selected": activeYear, years__today: sameYearAsToday });

							return (
								<li key={i} className="years__nav-item">
									<button type="button" className="years__nav-button" disabled={isDisabled} onClick={() => props.onChange(y)}>
										<p className={textClassName}>{y}</p>
									</button>
								</li>
							);
						})}
					</ul>
				</div>

				{displayYears && (
					<ul className="years__selection">
						<li className={navItemClassName}>
							<button type="button" className="years__selection-button years__selection-prev" onClick={onHandlePrevDates}>
								<ChevronIcon />
							</button>
						</li>

						{yearList.map((y, i) => {
							const sameYearAsToday = today === y;

							const activeYear = !defaultYear && props.year === y;

							const isMinYear = props.minDate ? props.minDate.getFullYear() > y : false;

							const isMaxYear = props.maxDate ? props.maxDate.getFullYear() < y : false;

							const isDisabled = isMinYear || isMaxYear;

							const textClassName = classNames({ years__text: true, "years__text-selected": activeYear, years__today: sameYearAsToday });

							return (
								<li key={i} className={navItemClassName}>
									<button type="button" className="years__selection-button" disabled={isDisabled} onClick={() => onHandleChangeYear(y)}>
										<p className={textClassName}>{y}</p>
									</button>
								</li>
							);
						})}

						<li className={navItemClassName}>
							<button type="button" className="years__selection-button years__selection-next" onClick={onHandleNextDates}>
								<ChevronIcon />
							</button>
						</li>
					</ul>
				)}
			</div>
		</div>
	);
};

export default memo(UnicornYears);
