import minusDays from "./minus-days";

const getFirstCellDate = (date) => {
	let day = date.getDay();

	/* if startDay = 0(Sunday) */
	/* need to add previous month 7 days */
	/* 24, 25, 26, 27, 28, 29, 3* */
	/* 01, 02, 03, 04, 05, 06, 07 */

	const isSunday = day === 0;

	if (isSunday) day = 7;

	const nextDate = minusDays(date, day - 1);

	return nextDate;
};

export default getFirstCellDate;
