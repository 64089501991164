import { memo, useMemo } from "react";

import classNames from "common/class-names";
import generateDays from "./common/generate-days";
import CalendarFormat from "./common/calendar-format";

const UnicornDays = (props) => {
	const days = useMemo(() => generateDays(CalendarFormat.Weekday_dd), []);

	return (
		<div className="unicorn-days">
			<div className="days">
				{days.map((d, i) => {
					const isSameDay = d.value === new Date().getDay();

					const textClassName = classNames({ days__text: true, days__today: isSameDay });

					return (
						<div className="days__day" key={i}>
							<p className={textClassName}>{d.day}</p>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default memo(UnicornDays);
