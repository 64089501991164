const getEndDate = (currentDate) => {
	const year = currentDate.getFullYear();

	const month = currentDate.getMonth();

	const nextDate = new Date(year, month + 1, 0);

	return nextDate;
};

export default getEndDate;
