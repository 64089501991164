import React, { useState, useEffect, useMemo, useRef, Fragment, useCallback } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import moment from "moment";

import api from "services/api";
import pathnames from "routes/pathnames";
import { formatDate } from "common/calendar";
import { promptAlertMessage } from "store/slices/alert";
import { formatCurrency } from "common/format-currency-pattern";
import AppTable from "components/app-table";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import addIcon from "assets/images/add-icon.svg";
import uploadIcon from "assets/images/upload-icon.svg";
import serveRequestErrors from "common/serve-request-errors";

const PageInvoiceBulkUpload = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const columnHelper = useMemo(() => createColumnHelper(), []);
	const dropAreaRef = useRef();
	const inputRef = useRef();
	const [data, setData] = useState();

	const onHandleSubmit = useCallback(async () => {
		let response = null;

		try {
			const payload = {
				invoices: data.invoices.map((o) => {
					const invoiceBusinessRegistrationNo = o.businessRegistrationNo;
					const company = data.issuer.filter((a) => a.companyRegistrationNo === invoiceBusinessRegistrationNo)[0];

					return {
						type: "INVOICE",
						template: "DEFAULT",
						email: {
							recipient: company.companyEmail,
							cc: [],
							subject: "E-invoice",
							sender: "noreply@altecflex.com",
							senderName: "noreply@altecflex.com",
							secret: "hywq4m70ppKK",
						},
						invoice: {
							invoiceNumber: o.invoiceNumber,
							invoiceDate: new Date(o.invoiceDate)?.toISOString(),
							purchaseNumber: o.invoicePurchaseNumber,
							purchaseDate: new Date(o.invoicePurchaseDate)?.toISOString(),
							paymentDueDate: new Date(o.invoicePaymentDueDate)?.toISOString(),
							tax: Number(o.invoiceTax),
							discount: Number(o.invoiceDiscount),
							total: Number(o.invoiceTotal),
							currencyCode: o.invoiceCurrency,
							issuer: {
								logo: "",
								logoWidth: "",
								logoHeight: "",
								name: company.companyName,
								companyRegistrationNumber: company.companyRegistrationNo,
								taxIdentificationNumber: company.invoiceTaxIdentificationNo,
								sstRegistrationNumber: company.invoiceSstIdentificationNo,
								address: {
									adddressOne: company.companyAdddress1,
									adddressTwo: company.companyAdddress2,
									state: company.companyState,
									city: company.companyCity,
									postcode: company.companyPostcode,
								},
								phoneNumber: company.companyMobileNo,
								officeNumber: company.officeNumber || "",
								email: company.companyEmail,
							},
							client: {
								name: company.companyName,
								phoneNumber: company.companyMobileNo,
								email: company.companyEmail,
								address: {
									adddressOne: company.companyAdddress1,
									adddressTwo: company.companyAdddress2,
									state: company.companyState,
									city: company.companyCity,
									postcode: company.companyPostcode,
								},
							},
							items: o.invoiceItems.map((a) => ({ ...a, costPerUnit: Number(a.costPerUnit), subtotal: Number(a.quantity || 1) * (Number(a.costPerUnit) || 0) })),
							payment: [""],
							notes: [""],
							tnc: "",
							themeColor: "",
							fontColor: "",
						},
						uniqueReferenceNumber: o.referenceNo || "",
						toSendEmail: false,
					};
				}),
			};

			response = await api.post.invoice.batch(payload);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			navigate(pathnames.dashboard);

			dispatch(promptAlertMessage({ message: "Invoices have been successfully uploaded." }));
		}
	}, [navigate, dispatch, data]);

	// prettier-ignore
	const onHandleDragEnter = (event) => {
        const child = dropAreaRef.current?.children?.[0];

        if(child) child.classList.add("invoice-bulk-upload__drop--dragover");

        event.preventDefault();
        event.stopPropagation();
    };

	// prettier-ignore
	const onHandleDragLeave = (event) => {
        const child = dropAreaRef.current?.children?.[0];

        if(child) child.classList.remove("invoice-bulk-upload__drop--dragover");

        event.preventDefault();
        event.stopPropagation();
    };

	// prettier-ignore
	const onHandleDragOver = (event) => {
        const child = dropAreaRef.current?.children?.[0];

        if(child) child.classList.add("invoice-bulk-upload__drop--dragover");

        event.preventDefault();
        event.stopPropagation();
    };

	const onHandleUploadInvoice = () => {
		inputRef.current.click();
	};

	const onHandleLoadFiles = (e) => {
		const bufferArray = e.target.result;

		const wb = XLSX.read(bufferArray, { type: "buffer" });

		const items = wb.Sheets.items;
		const issuer = wb.Sheets.issuer;
		const invoices = wb.Sheets.invoices;

		const itemsJson = XLSX.utils.sheet_to_json(items);
		const issuerJson = XLSX.utils.sheet_to_json(issuer);
		const invoicesJson = XLSX.utils.sheet_to_json(invoices);

		const nextIssuer = issuerJson.map((o) => ({
			companyName: o["COMPANY NAME"],
			companyRegistrationNo: o["COMPANY REGISTRATION NO"],
			companyMobileNo: o["MOBILE NO"],
			companyEmail: o["EMAIl ADDRESS"],
			companyAdddress1: o["ADDRESS 1"],
			companyAdddress2: o["ADDRESS 2"],
			companyState: o["STATE"],
			companyCity: o["CITY"],
			companyPostcode: o["POSTCODE"],
			invoiceTaxIdentificationNo: o["TIN"],
			invoiceSstIdentificationNo: o["SST NO"],
		}));

		const nextInvoices = [];

		invoicesJson.forEach((o) => {
			const businessRegistrationNo = o["BUSINESS REGISTRATION NO"];
			const invoiceNumber = o["INVOICE NO"];
			const invoicePurchaseNumber = o["INVOICE PURCHASE NO"];
			const invoiceDate = o["INVOICE DATE"];
			const invoicePurchaseDate = o["INVOICE PURCHASE DATE"];
			const invoicePaymentDueDate = o["INVOICE PAYMENT DUE DATE"];
			const invoiceTnc = o["INVOICE TNC"];
			const invoiceCurrency = o["INVOICE CURRENCY"];
			const invoiceTax = o["INVOICE TAX"];
			const invoiceDiscount = o["INVOICE DISCOUNT"];

			if (businessRegistrationNo || (!businessRegistrationNo && invoiceNumber && invoicePurchaseNumber && invoiceDate && invoicePurchaseDate && invoicePaymentDueDate)) {
				nextInvoices.push({
					businessRegistrationNo: businessRegistrationNo,
					invoiceNumber: invoiceNumber,
					invoicePurchaseNumber: invoicePurchaseNumber,
					invoiceDate: invoiceDate,
					invoicePurchaseDate: moment(invoicePurchaseDate).toISOString(),
					invoicePaymentDueDate: moment(invoicePaymentDueDate).toISOString(),
					invoiceTnc: invoiceTnc,
					invoiceCurrency: invoiceCurrency,
					invoiceTax: Number(invoiceTax),
					invoiceDiscount: Number(invoiceDiscount),
					invoiceItems: [],
					invoiceTotal: 0,
				});
			}
		});

		itemsJson.forEach((a) => {
			const invoiceNumber = a["INVOICE NO"];
			const invoice = nextInvoices.find((b) => b.invoiceNumber === invoiceNumber);

			if (invoice) {
				const invoiceItem = { item: a["INVOICE ITEM NAME"], costPerUnit: a["INVOICE ITEM COST/UNIT"], quantity: a["INVOICE ITEM QUANTITY"], subtotal: Number(a["INVOICE ITEM COST/UNIT"]) * Number(a["INVOICE ITEM QUANTITY"]) };

				invoice.invoiceItems.push(invoiceItem);

				invoice.invoiceTotal = invoice.invoiceItems.reduce((c, d) => c + d.subtotal, 0) - invoice.invoiceTax - invoice.invoiceDiscount;
			}
		});

		const businessRegistrationNoOptions = nextIssuer.map((o) => ({ label: o.companyRegistrationNo, value: o.companyRegistrationNo }));

		setData({ issuer: nextIssuer, invoices: nextInvoices, businessRegistrationNoOptions: businessRegistrationNoOptions });
	};

	// prettier-ignore
	const onHandleChange = (event) => {
		setData();

		const fileReader = new FileReader();

		fileReader.readAsArrayBuffer(event.target.files[0]);

		fileReader.onload = onHandleLoadFiles;
	};

	// prettier-ignore
	const onHandleDrop = useCallback((event) => {
        const child = dropAreaRef.current?.children?.[0];

        if(child) child.classList.remove("invoice-bulk-upload__drop--dragover");

		event.preventDefault();

		event.stopPropagation();

		const fileReader = new FileReader();

		fileReader.readAsArrayBuffer(event.dataTransfer.files[0]);

		fileReader.onload = onHandleLoadFiles;
	}, []);

	const onHandleChangeBusinessRegistrationNo = useCallback((event, obj) => {
		const value = event.target.value;
		const rowIndex = obj.row.index;

		setData((prev) => {
			const nextInvoices = [...prev.invoices];

			nextInvoices[rowIndex].businessRegistrationNo = value;

			return {
				...prev,
				invoices: nextInvoices,
			};
		});
	}, []);

	const onHandleCancel = () => {
		navigate(-1);
	};

	//prettier-ignore
	const issuerColumns = useMemo(() => [
		columnHelper.accessor("companyName", {
			header: "Company",
		}),
		columnHelper.accessor("companyRegistrationNo", {
			header: "Business Registration No.",
		}),
		columnHelper.accessor("companyEmail", {
			header: "Email Address",
		}),
		columnHelper.accessor("companyMobileNo", {
			header: "Mobile Number",
		}),
		columnHelper.accessor("invoiceTaxIdentificationNo", {
			header: "TIN",
		}),
		columnHelper.accessor("invoiceSstIdentificationNo", {
			header: "SST No.",
		}),
		columnHelper.accessor("companyAdddress1", {
			header: "Address 1",
		}),
		columnHelper.accessor("companyAdddress2", {
			header: "Address 2",
		}),
		columnHelper.accessor("companyState", {
			header: "State",
		}),
		columnHelper.accessor("companyCity", {
			header: "City",
		}),
		columnHelper.accessor("companyPostcode", {
			header: "Postcode",
		}),

	], [columnHelper]);

	//prettier-ignore
	const invoicesColumns = useMemo(() => [
		columnHelper.accessor("businessRegistrationNo", {
			header: "Business Registration No.",
            cell: (c) => <AppSelectInput value={c.getValue()} options={data.businessRegistrationNoOptions} placeholder="Please Select..." onChange={(event) => onHandleChangeBusinessRegistrationNo(event, c)} />
		}),
		columnHelper.accessor("invoiceNumber", {
			header: "Invoice No.",
		}),
		columnHelper.accessor("invoicePurchaseNumber", {
			header: "Invoice Purchase No.",
		}),
		columnHelper.accessor("invoiceDate", {
			header: "Invoice Date",
            cell: (c) => formatDate(c.getValue())
		}),
		columnHelper.accessor("invoicePurchaseDate", {
			header: "Invoice Purchase Date",
            cell: (c) => formatDate(c.getValue())
		}),
		columnHelper.accessor("invoicePaymentDueDate", {
			header: "Invoice Purchase Due Date",
            cell: (c) => formatDate(c.getValue())
		}),
		columnHelper.accessor("invoiceTnc", {
			header: "Invoice TNC",
		}),
		columnHelper.accessor("invoiceTax", {
			header: "Invoice Tax",
            cell: (c) => formatCurrency(c.getValue())
		}),
		columnHelper.accessor("invoiceDiscount", {
			header: "Invoice Discount",
            cell: (c) => formatCurrency(c.getValue())
		}),
		columnHelper.accessor("invoiceItems", {
			header: "Invoice Items",
            cell: (c) => {
				const items = c.row.original?.invoiceItems?.map((o) => o.item + " / " + formatCurrency(o.costPerUnit) + "(" + o.quantity + ")");

				return items.join(",\n");
			}
		}),
		columnHelper.accessor("invoiceTotal", {
			header: "Invoice Total",
            cell: (c) => formatCurrency(c.getValue())
		}),

	], [data, columnHelper, onHandleChangeBusinessRegistrationNo]);

	useEffect(() => {
		const droparea = dropAreaRef.current;

		droparea.addEventListener("dragenter", onHandleDragEnter);
		droparea.addEventListener("dragleave", onHandleDragLeave);
		droparea.addEventListener("dragover", onHandleDragOver);
		droparea.addEventListener("drop", onHandleDrop);

		return () => {
			if (droparea) {
				droparea?.removeEventListener("dragenter", onHandleDragEnter);
				droparea?.removeEventListener("dragleave", onHandleDragLeave);
				droparea?.removeEventListener("dragover", onHandleDragOver);
				droparea?.removeEventListener("drop", onHandleDrop);
			}
		};
	}, [onHandleDrop]);

	return (
		<div className="page-invoice-bulk-upload">
			<div className="invoice-bulk-upload">
				<h1 className="invoice-bulk-upload__title">Upload Insurers</h1>
				<p className="invoice-bulk-upload__description">Please upload your e-invoices-invoices.xlsx here.</p>

				<input ref={inputRef} hidden name="file" type="file" accept=".xlsx" onChange={onHandleChange} />

				<div className="invoice-bulk-upload__body">
					<div className="invoice-bulk-upload__droparea" ref={dropAreaRef} onClick={onHandleUploadInvoice}>
						{!data && (
							<div className="invoice-bulk-upload__drop">
								<div className="invoice-bulk-upload__icon">
									<img src={uploadIcon} alt="upload-icon" />
								</div>

								<p className="invoice-bulk-upload__placeholder">Drop your attachment here.</p>
							</div>
						)}
					</div>

					{data && (
						<Fragment>
							<div className="invoice-bulk-upload__table">
								<div className="invoice-bulk-upload__header">
									<AppButton type="button" outline label="REUPLOAD E-INVOICE" icon={addIcon} onClick={onHandleUploadInvoice} />
								</div>

								<AppTable data={data.issuer} columns={issuerColumns} />
							</div>

							<div className="invoice-bulk-upload__table">
								<AppTable data={data.invoices} columns={invoicesColumns} />
							</div>
						</Fragment>
					)}
				</div>

				<div className="invoice-bulk-upload__button-container">
					<AppButton large outline type="button" label="CANCEL" onClick={onHandleCancel} />

					<AppButton large type="button" label="SUBMIT" onClick={onHandleSubmit} />
				</div>
			</div>
		</div>
	);
};

export default PageInvoiceBulkUpload;
